import { isSSR } from './ssr';
class ReactPropsHelper {
    props = [];
    constructor() {
        if (isSSR()) {
            return;
        }
        const reactPropsNode = document.querySelector('#react_props');
        if (!reactPropsNode) {
            return;
        }
        this.props = JSON.parse(reactPropsNode.innerHTML);
    }
}
export default new ReactPropsHelper();
