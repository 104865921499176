import ReactPropsHelper from '@helpers/ReactPropsHelper';
import 'regenerator-runtime/runtime';
class IntersectionHydrator {
    observer;
    mapping = {};
    loadedScripts = [];
    constructor() {
        this.observer = new IntersectionObserver(this.handleIntersection, {
            threshold: 0,
            rootMargin: '0px',
        });
        this.regsiterComponents();
    }
    regsiterComponents = () => {
        // Loop through the components on the window
        for (let index = 0; index < ReactPropsHelper.props.length; index++) {
            const component = ReactPropsHelper.props[index];
            if (component.scripts.length === 0) {
                continue;
            }
            // get the entry point for the component
            const element = document.querySelector(`#${component.id}`);
            if (element === null || !this.observer) {
                continue;
            }
            // Assign the registion to the mapping
            this.mapping[component.id] = component;
            // Observe the element
            this.observer.observe(element);
        }
    };
    handleIntersection = (entries) => {
        for (const entry of entries) {
            if (!entry.isIntersecting) {
                continue;
            }
            const mapping = this.mapping[entry.target.id];
            if (!mapping) {
                continue;
            }
            for (const script of mapping.scripts) {
                const hasLoaded = this.loadedScripts.indexOf(script) > -1;
                if (!hasLoaded) {
                    // Not supported in Safari iOS
                    if (window.requestIdleCallback) {
                        window.requestIdleCallback(() => {
                            this.loadedScripts.push(script);
                            loadScript(script);
                        });
                    }
                    else {
                        this.loadedScripts.push(script);
                        loadScript(script);
                    }
                }
            }
        }
    };
}
function loadScript(url) {
    const promise = new Promise((resolve, reject) => {
        // Return false if we don't have a url
        if (!url || url.length === 0) {
            return reject(false);
        }
        // Create an image
        const script = document.createElement('script');
        // Resolve true if the load event runs
        script.addEventListener('load', () => {
            resolve(true);
        });
        // Reject false if the error event runs
        script.addEventListener('error', () => {
            reject(false);
        });
        // Set the src
        script.defer = true;
        script.src = url;
        document.head.appendChild(script);
    });
    return promise;
}
new IntersectionHydrator();
